<template lang="html">
  <div class="audio-call" :class="{ 'audio-call--active': callIsResponded }">
    <div class="audio-call__circle-image">
      <transition name="image-fade" mode="out-in">
        <img
          :src="imageSrc"
          :alt="initialData['full_image'].alt"
          :key="imageSrc"
        />
      </transition>
    </div>
    <div class="audio-call__text">
      <div class="audio-call__heading" v-if="initialData.heading">
        <span>{{ initialData.heading }}</span>
      </div>
      <div class="audio-call__subheading" v-if="initialData.subheading">
        {{ initialData.subheading }}
      </div>
    </div>

    <div
      class="audio-call__call-icon"
      :class="{
        'audio-call__call-icon--animated': callIsStart,
        'audio-call__call-icon--responded': callIsResponded,
      }"
    >
      <svg
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="startCall"
      >
        <path
          d="M62.37 45.3933C57.86 45.3933 53.4967 44.66 49.4267 43.34C48.1433 42.9 46.7133 43.23 45.7233 44.22L39.9667 51.4433C29.59 46.4933 19.8733 37.1433 14.7033 26.4L21.8533 20.3133C22.8433 19.2867 23.1367 17.8567 22.7333 16.5733C21.3767 12.5033 20.68 8.14 20.68 3.63C20.68 1.65 19.03 0 17.05 0H4.36333C2.38333 0 0 0.88 0 3.63C0 37.6933 28.3433 66 62.37 66C64.9733 66 66 63.69 66 61.6733V49.0233C66 47.0433 64.35 45.3933 62.37 45.3933Z"
          fill="#E9E3C7"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioCall',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      audio: null,
      callIsStart: false,
      callIsResponded: false,
      timeout: null,
      currentAudioTime: 0,
      imageSrc: this.initialData['full_image'].sizes.large,
    };
  },
  computed: {
    siteMap() {
      return this.$store.state.global.siteMap;
    },
  },
  mounted() {
    this.audio = new Audio();
  },
  methods: {
    playAudio(file) {
      this.audio.src = file;
      this.audio.play();
    },
    stopAudio() {
      this.audio.pause();
      this.audio.currentTime = 0;
    },
    startCall() {
      if (this.callIsStart) return;
      else if (this.callIsResponded) {
        this.endCall();
        return false;
      }
      this.callIsStart = true;

      this.playAudio(this.initialData['ring_audio'].url);

      this.timeout = setTimeout(() => {
        this.stopAudio(this.ringAudio);
        this.callIsStart = false;
        this.callIsResponded = true;
        this.playAudio(this.initialData.audio.url);
        this.audio.addEventListener('timeupdate', this.setCurrentAudioTime);
        this.audio.addEventListener('ended', this.endCall);
      }, 2000);
    },
    endCall() {
      this.$store.commit('routing/setCurrentRoute', this.siteMap.home);
    },
    setCurrentAudioTime() {
      this.currentAudioTime = this.audio.currentTime;

      if (this.initialData['images_by_seconds']) {
        this.initialData['images_by_seconds'].forEach(({ time, image }) => {
          if (this.currentAudioTime > this.timeToSeconds(time)) {
            this.imageSrc = image.sizes['medium_large'];
          }
        });
      }
    },
    timeToSeconds(strTime) {
      const timeArr = strTime.split(':');
      return Number(+timeArr[0] * 60 * 60 + +timeArr[1] * 60 + +timeArr[2]);
    },
  },
  beforeUnmount() {
    this.audio.removeEventListener('ended', this.endCall);
    this.audio.removeEventListener('timeupdate', this.setCurrentAudioTime);
    this.stopAudio();
    this.audio = null;
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
$transition: 0.8s ease;
.audio-call {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__circle-image {
    margin-bottom: 30px;
    margin-top: 10vh;
    transition: $transition;
    width: 100%;
    img {
      border-radius: 200px;
      box-shadow: 0 0 0 2px #85754e;
      width: 200px;
      height: 200px;
      display: block;
      margin: 0 auto;
      object-fit: cover;
      object-position: top center;
      transition: $transition;
    }
  }

  &__text {
    transition: $transition;
    overflow: hidden;
    height: 110px;
  }

  &__heading {
    @include tcl-h1;
    @include tcl-side-line;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    &:before {
      width: 110%;
      left: -5%;
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  &__subheading {
    margin-bottom: 20px;
    text-align: center;
    @include tcl-h3;
  }

  &__call-icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        transition: fill 0.4s;
      }
    }

    &--animated {
      svg {
        //animation: ring 1s ease infinite;
        animation-name: ring;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

    &--responded {
      svg {
        animation-name: rotate;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;

        path {
          fill: $orange;
        }
      }
    }
  }

  &--active {
    .audio-call {
      &__circle-image {
        margin-top: 0;
        margin-bottom: 0;
        img {
          width: 100%;
          height: 60vh;
          border-radius: 0;
          box-shadow: none;
        }
      }
      &__text {
        height: 0;
        opacity: 0;
      }
    }
  }
}

@keyframes ring {
  0% {
    transform: rotate(-15deg);
  }

  5% {
    transform: rotate(15deg);
  }

  10% {
    transform: rotate(-18deg);
  }

  15% {
    transform: rotate(18deg);
  }

  20% {
    transform: rotate(-22deg);
  }

  25% {
    transform: rotate(22deg);
  }

  30% {
    transform: rotate(-18deg);
  }

  35% {
    transform: rotate(18deg);
  }

  40% {
    transform: rotate(-12deg);
  }

  45% {
    transform: rotate(12deg);
  }

  50% {
    transform: rotate(0deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(135deg);
  }
}
</style>
